import React, { useEffect, useContext } from "react"

import Events from "@components/Events"
import {
  CanvasContainer,
  EndContent,
  AssetDescription,
  LandingContent,
  Timeline,
  OverlayContent,
  Footer,
  FastScrollOverlay,
  Head,
} from "@dom"
import { SiteContext } from "@context/siteContext"
import { useGlobalContext } from "@hooks"

export default function Index() {
  const { actions, ready, transition, isAudioSupported, isAudioActive } = useContext(SiteContext)
  const { isEndContentOnTop, loadingProgress } = useGlobalContext()

  useEffect(() => {
    if (loadingProgress > 0.1 && !ready && transition) {
      actions.setReady(true)
      actions.setBackground("black")
    }
  }, [loadingProgress])

  useEffect(() => {
    if (!transition) actions.setTransition(true)

    return () => {
      document.querySelectorAll("video").forEach((n) => n.remove())
      document.querySelectorAll("audio").forEach((n) => n.remove())
    }
  }, [])
  return (
    <>
      <Head />
      <Events />
      <OverlayContent
        mode={"timeline"}
        setIsAudioActive={actions.setIsAudioActive}
        isEndContentOnTop={isEndContentOnTop}
        isAudioSupported={isAudioSupported}
        isAudioActive={isAudioActive}
      />
      <div>
        <CanvasContainer />
        <EndContent mode={"timeline"} />
      </div>
      <Footer />

      <Timeline horizontal />
      <AssetDescription />
      <LandingContent />
      <FastScrollOverlay />
    </>
  )
}
